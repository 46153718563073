hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .home-nav {
    font-size: 20px;
  }
}

.home-nav-container {
    z-index: 999;
}

.home-nav {
  position: fixed;
  top: 5%;
  left: 5%;
  background-color: white;
  color: black;
  border-radius: 50%;
  border: 5px black solid;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  height: 70px;
  width: 70px;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Home navigation */
.home-nav a {
  color: white;
}

.home-nav:hover {
  transform: scale(1.1);
}

/* Banner img and title */
.banner-container {
  margin: 10% 0;
  padding: 0 30%;
}

.banner-container span {
  margin-left: 7px;
}

.banner-title {
  text-decoration: none;
  margin-bottom: 20px;
  text-align: center;
  color: black !important;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

a:hover,
a:active,
a:visited,
a:link {
  text-decoration: none;
}

@media (max-width: 768px) {
  .banner-container {
    margin: 30% 0;
    padding: 0 10%;
  }

  .banner-title {
    margin-bottom: 5px;
  }
}

.content h1 {
  margin-bottom: -15px;
}

.content h3 {
  color: black;
}

.content-block {
  padding: 0 27%;
  margin: 6% 0;
}

@media (max-width: 768px) {
  .content-block {
    padding: 0 10%;
    margin: 20% 0;
  }
}

.image-pane {
  text-align: center;
  width: 100%;
  padding: 5% 10%;
  margin-bottom: 6%;
  background-color: #eeeeee;
}

.image-pane img {
  height: 300px;
  margin: 0 15px;
  width: auto;
}

@media (max-width: 768px) {
  .image-pane img {
    width: 80%;
    height: auto;
    margin: 8% 0;
  }
}

.no-background {
  margin-top: -100px;
  margin-bottom: 0;
  background-color: white !important;
}

/* .middle-text{
    margin-top: 0px;
}  */

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 50px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/***************************** BUTTON STYLES ********************************/
.btn {
  border-radius: 0;
  font-family: "Metrophobic", sans-serif, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.btn-default {
  color: black;
  border: 1px solid #777;
  background-color: transparent;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  outline: 0;
  color: #fff;
  transform: scale(1.1);
}

/******************************** ANIMATION STUFF *****************************/
.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/****************************** DIFF BANNERS FOR EACH PAGE ****************************/

/* GRAPE */
.grape {
  color: #c399d1;
}

.grape h1 {
  color: #c399d1;
}

.grape .home-nav:hover,
.grape .home-have:active {
  color: #c399d1;
  border-color: #c399d1;
}

.grape .banner-title:hover,
.grape .banner-title:active {
  color: #c399d1 !important;
  transform: scale(1.2);
}

.grape .btn-default:hover,
.grape .btn-default:active,
.grape .btn-default:focus {
  border: 1px solid #c399d1;
  background-color: #c399d1;
}

/* BREWNGO */
.brewngo {
  color: #00ff90;
}

.brewngo h1 {
  color: #00ff90;
}

.brewngo .home-nav:hover,
.brewngo .home-have:active {
  color: #00ff90;
  border-color: #00ff90;
}

.brewngo .banner-title:hover,
.brewngo .banner-title:active {
  color: #00ff90 !important;
  transform: scale(1.2);
}

.brewngo .btn-default:hover,
.brewngo .btn-default:active,
.brewngo .btn-default:focus {
  border: 1px solid #00ff90;
  background-color: #00ff90;
}

/* VIOLET */
.violet {
  color: #70008d;
}

.violet h1 {
  color: #70008d;
}

.violet .home-nav:hover,
.violet .home-have:active {
  color: #70008d;
  border-color: #70008d;
}

.violet .banner-title:hover,
.violet .banner-title:active {
  color: #70008d !important;
  transform: scale(1.2);
}

.violet .btn-default:hover,
.violet .btn-default:active,
.violet .btn-default:focus {
  border: 1px solid #70008d;
  background-color: #70008d;
}
