/*************************** 'GLOBAL' CSS *********************************/

@import url('https://fonts.googleapis.com/css?family=Poppins|Raleway|Metrophobic|Hind');

.blue {
  color: #4fb0ff;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Metrophobic', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  background-color: #fff;
}

html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 35px;
  font-family: 'Metrophobic', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

h1,
h2 {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 36px;
}

p {
  color: #777777;
  font-size: 21px;
  line-height: 1.5;
}

a {
  color: #777777;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  font-size: 21px;
}

hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

@media(max-width: 767px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 24px;
    margin: 0;
  }

  .intro-body {
    text-align: center;
  }
}

@media(max-width: 1024px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  .intro-body {
    text-align: center;
  }
}

/************************************* HEADER STUFF *************************************/

.light {
  font-weight: 400;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #000;
}

@media(max-width: 768px) {
  .intro-name {
    padding-bottom: 10%;
  }
}

.intro-name {
  margin-bottom: 10px;
}

.intro-body {
  width: 550px;
}

.intro-body span {
  margin-left: 6px;
}

@media(max-width: 767px) {
  .navigation {
    display: grid;
  }

  .intro-body {
    width: 90%;
  }
}

/*************************************** NAVBAR ANIMATIONS ********************************/

a {
  text-decoration: none;
  margin: 3px 10px 3px 0px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  color: black;
}

a,
a:hover,
a:active,
a:visited,
a:focus,
a:link {
  text-decoration: none;
  color: black;
}

.topBottomBordersOut {
  font-family: 'Poppins', sans-serif;
}

/* Top and Bottom borders go out */
.topBottomBordersOut a:before,
div.topBottomBordersOut a:after {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 2px;
  background: #4fb0ff;
  content: "";
  opacity: 0;
  transition: all 0.3s;
}

.topBottomBordersOut a:before {
  top: 0px;
  transform: translateY(10px);
}

.topBottomBordersOut a:after {
  bottom: 0px;
  transform: translateY(-10px);
}

.topBottomBordersOut a:hover:before,
.topBottomBordersOut a:active:before,
.topBottomBordersOut a:hover:after,
.topBottomBordersOut a:active:after {
  opacity: 1;
  transform: translateY(0px);
}

/***************************** MODAL STUFF ********************************/
.media {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: .5%;
}

.media__container {
  padding: 100px 70px;
}

@media(max-width: 768px) {
  .media__container {
    padding: 5% 10%;
  }
}

.media__image {
  display: block;
  height: auto;
  width: 100%;
  border-radius: 2%;
}

@media(min-width:768px) {
  .media {
    max-width: 49%;
    height: auto;
  }
}

.media__body {
  background: rgba(41, 128, 185, 0.7);
  bottom: 0;
  color: white;
  font-size: 1em;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 3.75em 3em;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media__body p {
  color: white;
}

.media__body .link-button {
  border-color: white;
  color: white;
}

.media__body:hover,
.media__body:active {
  opacity: 1;
}

.media__body:after,
.media__body:before {
  display: inline;
  border: 1px solid rgba(255, 255, 255, 0.7);
  bottom: 1em;
  left: 1em;
  opacity: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.6s 0.2s;
  transition: 0.6s 0.2s;
}

.media__body:before {
  border-bottom: none;
  border-top: none;
  left: 2em;
  right: 2em;
}

.media__body:after {
  border-left: none;
  border-right: none;
  bottom: 2em;
  top: 2em;
}

.media__body:hover:after,
.media__body:hover:before,
.media__body:active:before,
.media__body:active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.media__body h2 {
  margin-top: 0;
}

@media(max-width:768px) {
  .media__body {
    padding: 10vw 10vw;
  }

  .media__body p {
    font-size: 16px;
    margin-top: 16px;
  }
}

.media__body p {
  margin-bottom: 1.5em;
}

/***************************** BUTTON STYLES ********************************/
.btn {
  border-radius: 0;
  font-family: "Metrophobic", sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  min-width: 150px;
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.btn-default {
  border: 1px solid #777;
  background-color: transparent;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  border: 1px solid #4fb0ff;
  outline: 0;
  color: #fff;
  background-color: #4fb0ff;
  transform: scale(1.1);
}

/*************************************** FOOTER STUFF ****************************/

.list-inline {
  display: inline-flex;
}

@media(max-width: 768px) {
  .list-inline {
    display: grid;
  }
}

.list-inline li {
  margin: 10px;
}

footer {
  padding: 50px 0;
}

footer p {
  margin: 0;
}

#contact {
  margin-top: 100px;
}

/******************************** ANIMATION STUFF *****************************/
.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}