@import url(https://fonts.googleapis.com/css?family=Poppins|Raleway|Metrophobic|Hind);
/*************************** 'GLOBAL' CSS *********************************/

.blue {
  color: #4fb0ff;
}

body {
  width: 100%;
  height: 100%;
  font-family: 'Metrophobic', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  background-color: #fff;
}

html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 35px;
  font-family: 'Metrophobic', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

h1,
h2 {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 36px;
}

p {
  color: #777777;
  font-size: 21px;
  line-height: 1.5;
}

a {
  color: #777777;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  font-size: 21px;
}

hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

@media(max-width: 767px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 24px;
    margin: 0;
  }

  .intro-body {
    text-align: center;
  }
}

@media(max-width: 1024px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 14px;
  }

  .intro-body {
    text-align: center;
  }
}

/************************************* HEADER STUFF *************************************/

.light {
  font-weight: 400;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: #000;
}

@media(max-width: 768px) {
  .intro-name {
    padding-bottom: 10%;
  }
}

.intro-name {
  margin-bottom: 10px;
}

.intro-body {
  width: 550px;
}

.intro-body span {
  margin-left: 6px;
}

@media(max-width: 767px) {
  .navigation {
    display: grid;
  }

  .intro-body {
    width: 90%;
  }
}

/*************************************** NAVBAR ANIMATIONS ********************************/

a {
  text-decoration: none;
  margin: 3px 10px 3px 0px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  color: black;
}

a,
a:hover,
a:active,
a:visited,
a:focus,
a:link {
  text-decoration: none;
  color: black;
}

.topBottomBordersOut {
  font-family: 'Poppins', sans-serif;
}

/* Top and Bottom borders go out */
.topBottomBordersOut a:before,
div.topBottomBordersOut a:after {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 2px;
  background: #4fb0ff;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.topBottomBordersOut a:before {
  top: 0px;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.topBottomBordersOut a:after {
  bottom: 0px;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.topBottomBordersOut a:hover:before,
.topBottomBordersOut a:active:before,
.topBottomBordersOut a:hover:after,
.topBottomBordersOut a:active:after {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

/***************************** MODAL STUFF ********************************/
.media {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: .5%;
}

.media__container {
  padding: 100px 70px;
}

@media(max-width: 768px) {
  .media__container {
    padding: 5% 10%;
  }
}

.media__image {
  display: block;
  height: auto;
  width: 100%;
  border-radius: 2%;
}

@media(min-width:768px) {
  .media {
    max-width: 49%;
    height: auto;
  }
}

.media__body {
  background: rgba(41, 128, 185, 0.7);
  bottom: 0;
  color: white;
  font-size: 1em;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 3.75em 3em;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media__body p {
  color: white;
}

.media__body .link-button {
  border-color: white;
  color: white;
}

.media__body:hover,
.media__body:active {
  opacity: 1;
}

.media__body:after,
.media__body:before {
  display: inline;
  border: 1px solid rgba(255, 255, 255, 0.7);
  bottom: 1em;
  left: 1em;
  opacity: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.6s 0.2s;
  transition: 0.6s 0.2s;
}

.media__body:before {
  border-bottom: none;
  border-top: none;
  left: 2em;
  right: 2em;
}

.media__body:after {
  border-left: none;
  border-right: none;
  bottom: 2em;
  top: 2em;
}

.media__body:hover:after,
.media__body:hover:before,
.media__body:active:before,
.media__body:active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.media__body h2 {
  margin-top: 0;
}

@media(max-width:768px) {
  .media__body {
    padding: 10vw 10vw;
  }

  .media__body p {
    font-size: 16px;
    margin-top: 16px;
  }
}

.media__body p {
  margin-bottom: 1.5em;
}

/***************************** BUTTON STYLES ********************************/
.btn {
  border-radius: 0;
  font-family: "Metrophobic", sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  min-width: 150px;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.btn-default {
  border: 1px solid #777;
  background-color: transparent;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  border: 1px solid #4fb0ff;
  outline: 0;
  color: #fff;
  background-color: #4fb0ff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*************************************** FOOTER STUFF ****************************/

.list-inline {
  display: inline-flex;
}

@media(max-width: 768px) {
  .list-inline {
    display: grid;
  }
}

.list-inline li {
  margin: 10px;
}

footer {
  padding: 50px 0;
}

footer p {
  margin: 0;
}

#contact {
  margin-top: 100px;
}

/******************************** ANIMATION STUFF *****************************/
.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .home-nav {
    font-size: 20px;
  }
}

.home-nav-container {
    z-index: 999;
}

.home-nav {
  position: fixed;
  top: 5%;
  left: 5%;
  background-color: white;
  color: black;
  border-radius: 50%;
  border: 5px black solid;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  height: 70px;
  width: 70px;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Home navigation */
.home-nav a {
  color: white;
}

.home-nav:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Banner img and title */
.banner-container {
  margin: 10% 0;
  padding: 0 30%;
}

.banner-container span {
  margin-left: 7px;
}

.banner-title {
  text-decoration: none;
  margin-bottom: 20px;
  text-align: center;
  color: black !important;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

a:hover,
a:active,
a:visited,
a:link {
  text-decoration: none;
}

@media (max-width: 768px) {
  .banner-container {
    margin: 30% 0;
    padding: 0 10%;
  }

  .banner-title {
    margin-bottom: 5px;
  }
}

.content h1 {
  margin-bottom: -15px;
}

.content h3 {
  color: black;
}

.content-block {
  padding: 0 27%;
  margin: 6% 0;
}

@media (max-width: 768px) {
  .content-block {
    padding: 0 10%;
    margin: 20% 0;
  }
}

.image-pane {
  text-align: center;
  width: 100%;
  padding: 5% 10%;
  margin-bottom: 6%;
  background-color: #eeeeee;
}

.image-pane img {
  height: 300px;
  margin: 0 15px;
  width: auto;
}

@media (max-width: 768px) {
  .image-pane img {
    width: 80%;
    height: auto;
    margin: 8% 0;
  }
}

.no-background {
  margin-top: -100px;
  margin-bottom: 0;
  background-color: white !important;
}

/* .middle-text{
    margin-top: 0px;
}  */

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 50px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/***************************** BUTTON STYLES ********************************/
.btn {
  border-radius: 0;
  font-family: "Metrophobic", sans-serif, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.btn-default {
  color: black;
  border: 1px solid #777;
  background-color: transparent;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  outline: 0;
  color: #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/******************************** ANIMATION STUFF *****************************/
.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/****************************** DIFF BANNERS FOR EACH PAGE ****************************/

/* GRAPE */
.grape {
  color: #c399d1;
}

.grape h1 {
  color: #c399d1;
}

.grape .home-nav:hover,
.grape .home-have:active {
  color: #c399d1;
  border-color: #c399d1;
}

.grape .banner-title:hover,
.grape .banner-title:active {
  color: #c399d1 !important;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.grape .btn-default:hover,
.grape .btn-default:active,
.grape .btn-default:focus {
  border: 1px solid #c399d1;
  background-color: #c399d1;
}

/* BREWNGO */
.brewngo {
  color: #00ff90;
}

.brewngo h1 {
  color: #00ff90;
}

.brewngo .home-nav:hover,
.brewngo .home-have:active {
  color: #00ff90;
  border-color: #00ff90;
}

.brewngo .banner-title:hover,
.brewngo .banner-title:active {
  color: #00ff90 !important;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.brewngo .btn-default:hover,
.brewngo .btn-default:active,
.brewngo .btn-default:focus {
  border: 1px solid #00ff90;
  background-color: #00ff90;
}

/* VIOLET */
.violet {
  color: #70008d;
}

.violet h1 {
  color: #70008d;
}

.violet .home-nav:hover,
.violet .home-have:active {
  color: #70008d;
  border-color: #70008d;
}

.violet .banner-title:hover,
.violet .banner-title:active {
  color: #70008d !important;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.violet .btn-default:hover,
.violet .btn-default:active,
.violet .btn-default:focus {
  border: 1px solid #70008d;
  background-color: #70008d;
}

